






























































import { capitalize } from "@/helpers/stringUtils";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { listCharges } from "@/api/membershipService";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "ReceiptList",
  setup() {
    const vm = reactive({
      charges: [] as Learnlink.Stripe.Charge[],
      loading: true,
    });

    onMounted(async () => {
      vm.charges = await listCharges(userModule.state.userId);
      vm.loading = false;
    });

    function getReadableTime(timestamp: number): string {
      return capitalize(Moment.unix(timestamp).format("LLLL"));
    }

    function openReceipt(url: string): void {
      window.open(url, "_blank")?.focus();
    }

    return {
      getReadableTime,
      openReceipt,
      vm,
    };
  },
});
