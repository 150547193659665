




















































import { getBalance } from "@/api/balanceService";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import { computed, defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import stripe from "@/services/stripe";
import TopUpBalanceDialog from "@/components/customer/TopUpBalanceDialog.vue";

export default defineComponent({
  name: "BalanceOverview",
  components: { TopUpBalanceDialog },
  props: {
    fillUpVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    size: {
      type: Number as PropType<number>,
      default: 32,
    },
    uid: {
      type: String as PropType<string>,
      default: userModule.state.userId,
    },
    width: {
      type: Number as PropType<number>,
      default: 6,
    },
    allInfo: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  },
  setup(props) {
    const vm = reactive({
      balance: {} as Learnlink.Balance.default,
      loading: true,
      topUpBalanceDialogVisible: false,
    });

    onMounted(async () => {
      try {
        await stripe.setup();
      }
      catch (e) {
        handleError(e);
      }

      vm.balance = await getBalance(props.uid);
      vm.loading = false;
    });

    const currentBalance = computed((): number => {
      return +(vm.balance.balance / 60).toFixed(2);
    });

    const previousRefill = computed(() => {
      const refills = vm.balance.transactions.filter((t) => t.minutes > 0);
      return refills[refills.length - 1];
    });

    const previousRefillText = computed(() => {
      if (previousRefill.value) {
        return `Forrige betaling ${Moment.unix(previousRefill.value.created).format("LLLL")}`;
      }
      return undefined;
    });

    const percentageUsed = computed(() => {
      if (previousRefill.value) {
        return (currentBalance.value / (previousRefill.value.minutes / 60)) * 100;
      }
      return undefined;
    });

    const isParent = computed(() => userModule.state.user.role === "parent");

    return {
      currentBalance,
      isParent,
      percentageUsed,
      previousRefill,
      previousRefillText,
      vm,
    };
  },
});
