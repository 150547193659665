import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/balances";

export async function getBalance(userId: string): Promise<Learnlink.Balance.default> {
  const balanceRes = await learnlinkAPI.axios.get<Learnlink.Balance.default>(
    `${ENDPOINT}/${userId}`,
  );
  return balanceRes.data;
}

export async function createTopOffSession(hours: number): Promise<Learnlink.Stripe.Session> {
  const sessionRes = await learnlinkAPI.axios.post<Learnlink.Stripe.Session>(
    `${ENDPOINT}/top-off/${hours}`
  );
  return sessionRes.data;
}

export async function getPricing(): Promise<number> {
  const pricingRes = await learnlinkAPI.axios.get<{price: number}>(
    `${ENDPOINT}/get-price`
  );
  return pricingRes.data.price;
}
