



































import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "PaidStamp",
  props: {
    paid: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    amount: {
      type: Number as PropType<number>,
      required: true
    }
  },
});
