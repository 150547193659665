

















































































import BalanceOverview from "@/components/customer/BalanceOverview.vue";
import InvoiceList from "@/components/membership/InvoiceList.vue";
import ReceiptList from "@/components/membership/ReceiptList.vue";
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive } from "@vue/composition-api";
import { uiModule } from "@/store/modules/ui";
import { useRouter } from "@/router";

export default defineComponent({
  name: "Payment",
  components: {
    ReceiptList,
    BalanceOverview,
    InvoiceList,
  },
  setup() {
    const { currentRoute } = useRouter();

    const vm = reactive({
      activePanel: null as number | null,
      status: currentRoute.value.params.status,
    });

    onMounted(() => {
      uiModule.actions.fetchUnpaidInvoices();
      vm.activePanel = uiModule.state.projectCount > 0 ? null : 0;
    });
    onBeforeUnmount(() => uiModule.actions.fetchUnpaidInvoices());

    return {
      projectCount: computed(() => uiModule.state.projectCount),
      unpaidInvoices: computed(() => uiModule.state.unpaidInvoices),
      vm,
    };
  },
});
