

































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import Moment from "@/services/moment";
import PaidStamp from "@/components/shared/PaidStamp.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "InvoiceCard",
  props: {
    receipt: {
      type: Object as PropType<Learnlink.Membership.Receipt>,
      required: true,
    },
  },
  components: { PaidStamp },
  setup(props) {
    const periodEnd = computed(() => {
      return props.receipt.invoice.lines.data[0].period.end;
    });

    const periodStart = computed(() => {
      return props.receipt.invoice.lines.data[0].period.start;
    });

    function openPaymentLink() {
      window.open(props.receipt.invoice.hosted_invoice_url, "_blank");
    }

    function openPDF() {
      window.open(props.receipt.invoice.invoice_pdf, "_blank");
    }

    return {
      moment: ref(Moment),
      openPaymentLink,
      openPDF,
      periodEnd,
      periodStart,
    };
  },
});
