



































































import InvoiceCard from "@/components/membership/InvoiceCard.vue";
import Learnlink from "@learnlink/interfaces";
import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { listInvoices } from "@/api/membershipService";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "InvoiceList",
  components: { InvoiceCard },
  setup() {
    const vm = reactive({
      invoices: [] as Learnlink.Membership.Receipt[],
      loading: true,
    });

    onMounted(async () => {
      vm.invoices = await listInvoices(userModule.state.userId);
      vm.loading = false;
    });

    const paid = computed(() => vm.invoices.filter((invoice) => invoice.invoice.paid));
    const unpaid = computed(() => vm.invoices.filter((invoice) => !invoice.invoice.paid));

    return {
      paid,
      unpaid,
      vm,
    };
  },
});
